import {Component, OnInit} from '@angular/core';
import * as _moment from 'moment';
import {Moment} from 'moment';
import {AudienceService} from '../../core/service/audience.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppConstants} from '../../app.constants';

const moment = _moment;
const COMPLETED = 'COMPLETED';
const IN_PROGRESS = 'IN_PROGRESS';
const FAILED = 'FAILED';

@Component({
  selector: 'app-dashboard-audience',
  templateUrl: './dashboard-audience.component.html',
  styleUrls: ['./dashboard-audience.component.scss']
})
export class DashboardAudienceComponent implements OnInit {

  public startAudienceDate: string = moment().subtract(7, 'days').format('DD/MM/YYYY');
  public endAudienceDate: string = moment().subtract(1, 'days').format('DD/MM/YYYY');
  public dataSourceAudiences: any;
  public lastTemperatureDateMeteoFrance = '-';
  public lastIntegrationDateAverageUserFrequency = '-';
  public isLoading = true;
  public audienceForm: FormGroup;
  public isLoadingIntegration = false;
  public nowDate: Moment = moment();
  public isErrorIntegration = false;
  public errorMessage = '';
  public adServers = ['Freewheel', 'AdManager', 'France24', 'Equativ'];
  public integratedLinesCount = 0;
  public integratedFileName = '';
  public columnListAudiences =
    [
      'date',
      'directVideo',
      'unsold',
      'dfp',
      'france24',
      'bySiteEmission',
      'bySiteRubrique',
      'tvSeg',
      'rubrique',
    ];
  public columnsToDisplayAudiences = [
    {
      audienceType: 'date',
      title: 'Date des audiences',
      subtitle: '',
      hasImpressions: false,
    },
    {
      audienceType: 'directVideo',
      title: 'Direct',
      subtitle: 'J - 1',
      hasImpressions: true,
    },
    {
      audienceType: 'unsold',
      title: 'Inventaire',
      subtitle: 'J - 1',
      hasImpressions: true,
    },
    {
      audienceType: 'dfp',
      title: 'Display',
      subtitle: 'J - 1',
      hasImpressions: true,
    },
    {
      audienceType: 'france24',
      title: 'France 24',
      subtitle: 'J - 1',
      hasImpressions: true,
    },
    {
      audienceType: 'bySiteEmission',
      title: 'Par site (emission)',
      subtitle: 'J - 1',
      hasImpressions: true,
    },
    {
      audienceType: 'bySiteRubrique',
      title: 'Par site (rubrique)',
      subtitle: 'J - 1',
      hasImpressions: true,
    },
    {
      audienceType: 'tvSeg',
      title: 'TV seg',
      subtitle: 'J - 1',
      hasImpressions: true,
    },
    {
      audienceType: 'rubrique',
      title: 'Rubrique',
      subtitle: 'J - 1',
      hasImpressions: true,
    },
  ];

  public audienceTypes = [
    {
      type: 'audience',
      name: 'Direct'
    },
    {
      type: 'audience_invent_oe',
      name: 'Inventaire autres éditeurs'
    },
    {
      type: 'audience_invent_ftv',
      name: 'Inventaire FTV'
    },
    {
      type: 'audience_by_site',
      name: 'Par site'
    },
    {
      type: 'audience_invent_site',
      name: 'Inventaire par site'
    },
    {
      type: 'audience_seg_video',
      name: 'TV seg'
    },
    {
      type: 'audience_programmatic',
      name: 'Programmatique'
    },
    {
      type: 'audience_rubrique',
      name: 'Rubrique'
    },
    {
      type: 'average_user_frequency',
      name: 'Répétition moyenne'
    },
    {
      type: 'audience_unique_user',
      name: 'Utilisateurs unique'
    },
    {
      type: 'audience_creative',
      name: 'Créative'
    }
  ];

  constructor(
    private audienceService: AudienceService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) { }


  ngOnInit() {
    this.initForm();
    this.getAudiencesOverview();
  }

  private getAudiencesOverview() {
    this.audienceService
      .getAudiencesOverview()
      .subscribe(
        (data) => {
          this.dataSourceAudiences = new MatTableDataSource(data.audiences);
          this.lastTemperatureDateMeteoFrance = data.meteoFrance.temperatureDate;
          this.lastIntegrationDateAverageUserFrequency = data.averageUserFrequency.lastIntegrationDate;
          this.isLoading = false;
        },
        () => this.isLoading = false
      );
  }

  private initForm() {
    this.audienceForm = this.fb.group({
      broadcastAudience : [
        this.nowDate.subtract(1, 'days'),
        Validators.required,
      ],
      adServer : ['Freewheel'],
      audienceType : [
        this.audienceTypes[0],
        [Validators.required],
      ]
    });
  }

  public onSubmit () {
    if (this.audienceForm.valid) {
      if (['AdManager', 'France24', 'Equativ'].includes(this.audienceForm.get('adServer').value)) {
        this.integrateAdManagerAudiences(null, this.isFrance24(), this.isEquativ());
      } else {
        this.integrateFwAudiences();
      }
    }
  }

  private integrateAdManagerAudiences(reportJobId = null, f24 = false, eqtv=false) {
    this.isLoadingIntegration = true;
    this.snackBar.open(
      'Intégration des audiences en cours ! Veuillez patienter...',
      null,
      { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
    );
    this.audienceService
      .integrateAdManagerAud({
        'broadcastAudience': this.audienceForm.get('broadcastAudience').value,
        'reportJobId': reportJobId,
        'f24': (f24 ? 1 : 0),
        'eqtv': (eqtv ? 1 : 0),
      })
      .subscribe(
        audienceResponse => {
          switch (audienceResponse.reportJobStatus) {
            case IN_PROGRESS:
              setTimeout(() => {
                this.integrateAdManagerAudiences(audienceResponse.reportJobId, this.isFrance24(), this.isEquativ());
              }, 20000);
              break;
            case COMPLETED:
              this.snackBar.open(
                'Intégration des audiences terminée !',
                null,
                { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
              );
              this.isLoadingIntegration = false;
              break;
            case FAILED:
              this.isLoadingIntegration = false;
              break;
            default:
              this.isLoadingIntegration = false;
              break;
          }
        },
        error => {
          this.isLoadingIntegration = false;
        }
      );
  }

  private integrateFwAudiences(isAlreadyRunning = false) {
    this.errorMessage = '';
    this.isErrorIntegration = false;
    this.isLoadingIntegration = true;

    if (! isAlreadyRunning) {
      this.snackBar.open(
        'Intégration des audiences en cours ! Veuillez patienter...',
        null,
        { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
      );
    }

    this.errorMessage = 'Intégration en cours ... ';

    this.audienceService
      .integrateFwAud({
        'broadcastAudience': moment(this.audienceForm.get('broadcastAudience').value).format('YYYY-MM-DD'),
        'audienceType': this.audienceForm.get('audienceType').value.type,
        'alreadyRunning': (isAlreadyRunning) ? 1 : 0
      })
      .subscribe(
        audienceResponse => {
          if (audienceResponse.integrateError === 504) {
            this.isErrorIntegration = true;
            setTimeout(() => {
              this.integrateFwAudiences(true);
            }, 10000);
          } else {
            this.integratedLinesCount = audienceResponse.integratedLinesCount;
            this.integratedFileName = audienceResponse.integratedFileName;
            this.isErrorIntegration = false;
            this.isLoadingIntegration = false;
          }
        },
        error => {
          if (error.status === 504) {
            setTimeout(() => {
              this.integrateFwAudiences(true);
            }, 10000);
          } else {
            this.errorMessage = error.message;
            this.isErrorIntegration = true;
            this.isLoadingIntegration = false;
          }
        }
      );
  }

  private isFrance24(): boolean {
    return (this.audienceForm.get('adServer').value === 'France24');
  }

  private isEquativ(): boolean {
    return (this.audienceForm.get('adServer').value === 'Equativ');
  }

  changeAdServerHandler(adServer: string) {
    this.audienceForm.controls.adServer.patchValue(adServer);
  }

  public formatDate(date: string) {
    if (typeof date === 'string') {
      const inputDatePattern = /(\d{4})-(\d{2})-(\d{2})/;
      const matches = date.match(inputDatePattern);

      if (matches && matches.length > 0) {
        const dateParts = date.split('-');
        if (dateParts && dateParts.length === 3) {
          return dateParts[2] + '/' + dateParts[1] + '/' + dateParts[0];
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
  }
}
