import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { AppConstants } from '../../../app.constants';
import {DealTypeService} from '../../../core/service/deal-type.service';
import {BuyerService} from '../../../core/service/buyer.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {JsonStep} from '../../../core/model/step.model';

@Component({
  selector: 'app-programmatic-deal',
  templateUrl: './programmatic-deal.component.html',
  styleUrls: ['./programmatic-deal.component.scss']
})
export class ProgrammaticDealComponent implements OnInit {

  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeVideo;
  @Input() purchase$;
  @Input() purchaseStep: JsonStep;
  @Output() dealTypeChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  public dealProgrammaticForm: FormGroup;
  public dealTypes: any ;
  public buyers: any;
  public buyerLoading: boolean;
  public dealTypesRaw: any;
  public dealProgrammatic = {};
  public dealId: any;
  public externalDealId: any;
  public currentDealType: any;
  protected currentBuyer: any;
  protected loading = false;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    protected dealTypeService: DealTypeService,
    protected buyerService: BuyerService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.loadDealData();
    this.getDealTypes();
  }

  protected displayDealType = (value) => {
    if (value && value.label) {
      return value?.labelFr;
    }
    if (this.dealTypes) {
      return this.dealTypes.find(option => option.id ===  value)?.labelFr;
    }
  }

  protected displayBuyer = (value) => {
    if (this.currentBuyer?.externalDealId) {
      return this.currentBuyer.externalSeatId +  '-' + this.currentBuyer.tradingDesk +
        ' / ' + this.currentBuyer.buyerPlatform;
    }
    if (value && typeof value === 'string') {
      return value;
    }

    if (value && value.externalSeatId) {
      return value.externalSeatId +  '-' + value.tradingDesk +
        ' / ' + value.buyerPlatform;
    }

    return '';
  }

  private initForm(): void {
    this.dealProgrammaticForm = this.fb.group({
      dealType: ['', [Validators.required]],
      externalDealId: [''],
      dealName: [''],
      dealId: [''],
      buyer: [''],
    });

    this.dealProgrammaticForm.get('buyer').valueChanges
      .subscribe(
        (value) => {
          if (value && value.length < 1) {
            this.buyers = null;
            this.buyerLoading = false;
            return;
          }
          this.buyerLoading = true;
          this.buyerService.getBuyers(value).subscribe(
            (result) =>  {
              this.buyers = result;
              this.buyerLoading = false;
            }
          );
        }
      );

    this.dealProgrammaticForm.get('dealType').valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe(
      (value) =>  {
        if (value?.id) {
          this.currentDealType = value.id;
          this.dealTypeChangeEvent.emit(value.id);
        } else {
          this.currentDealType = null;
          this.dealTypeChangeEvent.emit(null);
        }
      }
    );
  }


  private loadDealData(): void {
    this.store.select('currentPurchaseItems').subscribe(
      (currentPurchaseItems) => {
        if (!this.purchaseItemId) {
          return;
        }

        let items: any = null;
        if (this.dispoType === AppConstants.typeVideo) {
          items = currentPurchaseItems.video;
        } else if (this.dispoType === AppConstants.typeSegmentalTv) {
          items = currentPurchaseItems.segmentalTv;
        }

        if (!items) { return; }

        for (const item of items) {
          if (item && item.id == this.purchaseItemId) {
            this.dealId = item?.dealProgrammatic?.dealId;
            const buyer = item?.dealProgrammatic?.buyer;
            if (buyer) {
              this.buyerService.getBuyers(buyer).subscribe(
                (result) =>  {
                  Object.entries(result).forEach(
                    ([val]) => {
                        this.currentBuyer = result[val] ?? null;
                        this.dealProgrammaticForm.patchValue( this.displayCurrentBuyer(this.currentBuyer));
                    });
                }
              );
            }

            this.currentDealType = item?.dealProgrammatic?.dealType;
            this.dealId = item?.dealId;
            this.externalDealId =  item?.dealProgrammatic?.externalDealId;
            this.dealProgrammaticForm.patchValue({
              dealName: item?.dealProgrammatic?.dealName,
              dealId: this.dealId,
              ...item?.dealProgrammatic?.externalDealId ?
                {
                  externalDealId: this.externalDealId
                } : {}
            });
            break;
          }
        }
      });
  }

  private getDealTypes(): void {
    this.dealTypeService.getDealTypes().subscribe(
      (dealTypes) =>  {
        this.dealTypes =  dealTypes;
        this.dealTypesRaw = this.dealTypes;
        this.dealTypes.forEach(
          (dealType) => {
            if (this.currentDealType === dealType.id) {
              this.dealProgrammaticForm.patchValue({
                dealType: dealType,
              });
            }
          }
        );
    });
  }

  protected generateDealName() {
    this.loading = true;
    this.dealTypeService.getDealName(this.purchaseItemId).subscribe(
      (dealName) =>  {
        this.loading = false;
        this.dealProgrammaticForm.patchValue({
          'dealName': dealName,
        });
      }
    );
  }

  private displayCurrentBuyer(currentBuyer = null) {
    return currentBuyer ? {
      buyer: currentBuyer.externalSeatId +
        '-' + currentBuyer.tradingDesk +
        ' / ' + currentBuyer.buyerPlatform +
        ' (' + currentBuyer.id + ')'
    } : null;
  }

  public restrictDealName(event) {
    if (event?.target?.value?.length >= AppConstants.purchase.programmatic.purchaseItem.deal.nameMaxLength) {
      event.target.value = event.target.value.slice(0, AppConstants.purchase.programmatic.purchaseItem.deal.nameMaxLength - 1);
    }
  }
}

