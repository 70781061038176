import {AfterContentInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {BuyerService} from '../../../core/service/buyer.service';
import {AppConstants} from '../../../app.constants';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-buyer',
  templateUrl: './edit.component.html',
})
export class EditComponent implements AfterContentInit {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public buyerBusinessNameForm: FormGroup;
  public saving = false;
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditComponent>,
    protected buyerService: BuyerService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        modalTitle?: string,
        modalBtnCancel: any,
        modalBtnValid: any,
        modalOnClose: () => void,
        buyer: {
          businessName?: string
          tradingDesk?: string
        },
      }) {
  }

  public ngAfterContentInit() {
    this.initForm();
  }

  public closeDialog(message = ''): void {
    this.dialogRef.close(message);
    this.data.modalOnClose();
  }

  public saveBuyerBusinessName(): void {
    this.saving = true;

    if (this.data?.buyer) {
      this.buyerService
        .saveItem(this.data.buyer.tradingDesk, this.buyerBusinessNameForm.value)
        .subscribe(
          () => {
            this.saving = false;
            this.closeDialog('Le buyer a bien été mis à jour');
          },
          error => {
            this.saving = false;
            this.snackBar.open(
              'Le processus de mise à jour ne s\'est pas correctement terminé. Essayez de saisir un nom commercial différent.',
              null,
              {
                duration: AppConstants.snackBarDuration,
                verticalPosition: 'top',
                panelClass: ['chip-error']
              });
          }
        );
    } else {
      this.saving = false;
    }
  }

  private initForm(): void {
    this.buyerBusinessNameForm = this.fb.group({
      buyer: [
        this.data.buyer?.businessName
      ],
    });
  }
}
