
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Campaign} from '../model/campaign.model';
import {environment} from '../../../environments/environment';
import {CampaignSegmental} from '../model/campaign-segmental.model';
import {LinearCampaign} from '../model/linear-campaign.model';
import {LinearSpot} from '../model/linear-spot.model';
import {HttpClient} from '@angular/common/http';

export interface CampaignFilter {
  product_id?: number;
  dateStart?: Date;
  dateEnd?: Date;
  idAdServer?: any;
  numRegie?: string;
  nummode?: number;
  id?: string;
  dealType?: number;
}

export interface LinearCampaignFilter {
  itemPerPage?: number;
  page?: number;
  sortActive?: string;
  sortDirection?: string;
  product_id?: number;
  dateStart?: Date;
  dateEnd?: Date;
  id?: string;
  arg?: string;
}

@Injectable()
export class CampaignService {

  private route = '/digital/campaign';
  private createTvSegCampaignRoute = '/digital/create-tv-campaign';
  private linearCampaignRoute = '/linearcampaign';
  private linearSpotRoute = '/linearspot';
  private pushLinearSpotRoute = '/push-linear-spot';
  private pushSpotToDuplicateRoute = '/push-dup-spot';
  private pushLinearCampaignToFwRoute = '/digital/push_linear_campaign_fw';
  private pushTvSpotRoute = '/push-tv-spot';
  private pushTvScreenRoute = '/push-tv-screen';

  constructor(private httpService: HttpClient) {
  }

  public getList(filter: CampaignFilter): Observable<Campaign[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService
      .get(url).pipe(
      map(response => {
          return response['_embedded']['campaign'].map(
            jsonCampaign => new Campaign(jsonCampaign)
          );
      }));

  }

  public getSegmentalList(filter: CampaignFilter): Observable<CampaignSegmental[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService
      .get(url).pipe(
        map(response => {
          return response['_embedded']['campaign'].map(
            jsonCampaign => {
              return new CampaignSegmental(jsonCampaign);
            });
        }));
  }

  public createTvSegCampaign(): Observable<any> {
    const url = environment.adspace_api_base_url + this.createTvSegCampaignRoute;
    return Observable.create(observer => {
      this.httpService
          .get(url)
          .subscribe(
              response => observer.next(response),
              error => observer.error(error)
          );
    });
  }

  public getLinearCampaign(filter: any): Observable<any> {
    let url = environment.adspace_api_base_url + this.linearCampaignRoute;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService
      .get(url).pipe(
        map(response => {
          const list = this.getItemData(response['_embedded']['linearcampaign']);
          return {
            'list': list,
            'total': response['_embedded']['linearcampaign'][list.length]['total_count']  
          };
        }));
  }

  public getItemData(data) {
    let rs = [];
    for (let i = 0, len = data.length; i < len; i++) {
      if (data[i] && data[i]['id']) {
        rs.push(new LinearCampaign(data[i]));
      }
    }

    return rs;
  }

  public getLinearSpot(filter: any): Observable<any> {
    let url = environment.adspace_api_base_url + this.linearSpotRoute;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return this.httpService
      .get(url).pipe(
        map(response => {
          let list = [];
          for (let i = 0, len = response['_embedded']['linearspot'].length; i < len; i++) {
            if (response['_embedded']['linearspot'][i] && response['_embedded']['linearspot'][i]['id']) {
              list.push(new LinearSpot(response['_embedded']['linearspot'][i]));
            }
          }

          return {
            'list': list,
            'total': response['_embedded']['linearspot'][list.length]['total_count']  
          };
        }));
  }

  /**
   * @param data
   */
   public saveLinearCampaign(data: any): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .put(`${environment.adspace_api_base_url + this.linearCampaignRoute}/${data['id']}`, data)
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }

  /**
   * Save linear campaign.
   *
   * @param data
   */
  public createLinearCampaign(data: any): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .post(environment.adspace_api_base_url + this.linearCampaignRoute, data)
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }

  public pushLinearSpot(): Observable<any> {
    const url = environment.adspace_api_base_url + this.pushLinearSpotRoute;
    return Observable.create(observer => {
      this.httpService
          .get(url)
          .subscribe(
              response => observer.next(response),
              error => observer.error(error)
          );
    });
  }

  public pushSpotToDuplicate(): Observable<any> {
    const url = environment.adspace_api_base_url + this.pushSpotToDuplicateRoute;
    return Observable.create(observer => {
      this.httpService
          .get(url)
          .subscribe(
              response => observer.next(response),
              error => observer.error(error)
          );
    });
  }

  public pushTvSpot(): Observable<any> {
    const url = environment.adspace_api_base_url + this.pushTvSpotRoute;
    return Observable.create(observer => {
      this.httpService
          .get(url)
          .subscribe(
              response => observer.next(response),
              error => observer.error(error)
          );
    });
  }
  public pushTvScreen(): Observable<any> {
    const url = environment.adspace_api_base_url + this.pushTvScreenRoute;
    return Observable.create(observer => {
      this.httpService
          .get(url)
          .subscribe(
              response => observer.next(response),
              error => observer.error(error)
          );
    });
  }

  public pushLinearCampaignToFw(): Observable<any> {
    const url = environment.adspace_api_base_url + this.pushLinearCampaignToFwRoute;
    return Observable.create(observer => {
      this.httpService
        .get(url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }

  /**
   * @param id
   */
   public deleteLinearCamp(id: number): Observable<any[]> {
    return Observable.create(observer => {
      this.httpService
        .delete(environment.adspace_api_base_url + this.linearCampaignRoute + '/' + id)
        .subscribe(
          response => observer.next(response),
          error => observer.error(error)
        );
    });
  }
}
