
import {of as observableOf, forkJoin as observableForkJoin} from 'rxjs';

import { map, tap, filter, debounceTime, startWith} from 'rxjs/operators';
import { Component, OnInit, Inject, ElementRef, ViewChild} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import { Observable} from 'rxjs/Observable';
import {Product} from '../../core/model/product.model';
import {Campaign} from '../../core/model/campaign.model';
import {ProductService} from '../../core/service/product.service';
import {CampaignService} from '../../core/service/campaign.service';

import {Router} from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {PurchaseService} from '../../core/service/purchase.service';
import {PurchaseItemService} from '../../core/service/purchase-item.service';
import { Moment} from 'moment';
import * as _moment from 'moment';
const moment = _moment;
import {Advertiser} from '../../core/model/advertiser.model';
import {AdvertiserService} from '../../core/service/advertiser.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppState, GetPurchaseSuccess } from '../../store';
import { Store } from '@ngrx/store';
import { AppConstants } from '../../app.constants';
import { ChangeDetectorRef } from '@angular/core';
import { dateCurrentYearCheckValidator } from '../../core/validator/date-current-year-check.validator';
import { FsaService } from '../../core/service/fsa.service';
import { forkJoin } from 'rxjs';
import {DealType} from '../../core/model/deal.model';
import {MessageDialogComponent} from '../../purchase/shared/message-dialog/message-dialog.component';
import { PurchaseItemsConflictsDialogComponent } from '../../purchase/purchase-detail/purchase-items-conflicts-dialog/purchase-items-conflicts-dialog.component';
import {Purchase} from '../../core/model/purchase.model';

@Component({
  selector: 'app-dashboard-purchase',
  templateUrl: './dashboard-purchase.component.html',
  styleUrls: ['./dashboard-purchase.component.scss']
})

export class DashboardPurchaseComponent implements OnInit {

  public changes = [
    {viewValue: '€ net'},
  ];

  public purchaseForm: FormGroup;
  public  products$: Observable<Product[]>;
  public campaigns$: Observable<Campaign[]>;
  public nowDate: Moment = moment();
  public advertisers$: Observable<Advertiser[]>;
  public loading = false;
  public dataPurchase = null;
  private dataCampaign = null;
  private dataBroadcastStart = null;
  private dataBroadcastEnd = null;
  public loadingProduct = false;
  public loadingAdvertiser = false;
  public loadingOffer = false;
  public dealTypes: DealType[] | null;
  public dealTypesRaw: number | null;
  protected dateStartLocked: boolean = false;
  private isProgrammatic: boolean = false;
  protected minMaxDate: Moment = null;
  protected minDate: Moment = null;

  @ViewChild('productAutocompleteElem', {static: false}) productAutocompleteElem: ElementRef;
  @ViewChild('advertiserAutocompleteElem', {static: false}) advertiserAutocompleteElem: ElementRef;

  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private campaignService: CampaignService,
    public dialogRef: MatDialog,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private purchaseService: PurchaseService,
    private purchaseItemService: PurchaseItemService,
    private advertiserService: AdvertiserService,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: ChangeDetectorRef,
    private fsaService: FsaService,
  ) {  }

  ngOnInit() {
    this.initDealTypes();
    this.initForm();

    if (this.data && this.data.purchase) {
      this.data.purchase.subscribe(res => {
        this.purchaseForm.patchValue(res);
        this.initProgrammaticValues(res);
      });
    }

    if (this.data) {
      this.initLocalValues(this.data);
    }

    // Product Autocomplete
    this.startProductAutocomplete();

    // Advertiser Autocomplete
    this.startAdvertiserAutocomplete();

    // Campaign auto fill other fields
    this.fillFieldsWithCampaign();

    this.onChanges();
  }

  public compareFn(obj1, obj2): boolean {
    if (obj1 && obj2) {
     return (obj1['id'] === obj2['id']);
   } else {
     return false;
   }
  }

  private initForm() {
    this.purchaseForm = this.fb.group({
      id: [''],
      step: [''],
      title: [
        '',
        [
          Validators.required,
          Validators.maxLength(150)
        ]
      ],
      dealType: [this.dataPurchase?.dealType, this.dealTypes?.length > 1 ? [Validators.required] : []],
      advertiserTemporary: [''],
      productTemporary: this.data.product || this.data.productTemporary,
      product: [''],
      agencyTemporary: [''],
      campaign: (this.data.campaign) ? this.data.campaign : null,
      reference: ['', Validators.maxLength(25)],
      parrainage: [false],
      folderNum: [
        '',
        [Validators.minLength(3)],
        this.checkFsaExistency.bind(this)],
      broadcastStart: [
        '',
        [Validators.required]
      ],
      broadcastEnd: [
        '',
        [Validators.required]
      ],
      caNet: [''],
      currency: [this.changes[0].viewValue],
    },
    {
      validator : dateCurrentYearCheckValidator
    });
  }

  public closeDialog(reloadNeeded): void {
    reloadNeeded ? location.reload() :this.dialogRef.closeAll();  
  }


  public onSubmit() {
    if (this.purchaseForm.valid) {
      this.loading = true;

      if (typeof this.purchaseForm.value.productTemporary !== 'string') {
        this.purchaseForm.patchValue({
          product: this.purchaseForm.value.productTemporary,
        });
      } else {
        this.purchaseForm.patchValue({
          product: null
        });
      }

      if (this.data && this.data.purchase) {
        this.getItemsInEditConflict().length > 0 ?
          this.warnUIforEditConflict() : 
          this.editPurchase(false);
      } else {
        this.createPurchase();
      }
    }
  }

  // function to warn user when he tries to edit a purchase with some items in edit conflict with the new broadcastStart
  public async warnUIforEditConflict(): Promise<void> {
    this.loading = false;

    // It's not possible to edit a purchase with step up to 1 or with  finished purchaseItems
    if ( this.dataPurchase?.step.id > 1 && this.completedItemsCheck()) {
      this.dialogRef.open(MessageDialogComponent, {
        width: '600px',
        disableClose: true,
        data: {
          modalTitle: '',
          modalBtnValid: 'OK',
          confirmQuestion: false,
          message: 'Certains dispositifs sont terminés, vous ne pouvez pas modifier la date de début de l\'achat au delà de la date de fin d\'un dispotitif '
        }
      });
    } else {
      if (await this.audienceDeliveredItemsCheck()) {
        this.dialogRef.open(PurchaseItemsConflictsDialogComponent, {
          width: '600px',
          disableClose: true,
          data: {
            modalTitle: '',
            modalBtnCancel: 'OK',
            confirmQuestion: false,
            message: 'La date de départ de l\'achat est supérieure à la date de début de diffusion de certains dispositifs contenant déjà des audiences.',
            listTitle: 'Dispositifs impactés :',
            itemlist: this.getItemsInEditConflictWithAudience()
          }
        });
      } else {
        const dialogRef = this.dialogRef.open(PurchaseItemsConflictsDialogComponent, {
          width: '600px',
          disableClose: true,
          data: {
            modalTitle: '',
            modalBtnCancel: 'Annuler',
            modalBtnValid: 'Valider',
            confirmQuestion: false,
            message: 'Cette action va modifier la date de départ des dispositifs.',
            listTitle: 'Dispositifs impactés :',
            itemlist: this.getItemsInEditConflict()
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === 'save') {
            this.loading = true;
            this.purchaseItemService.editItemsBroadcastStart(this.dataPurchase.id.toString(), this.purchaseForm.get('broadcastStart').value)
              .subscribe();
            this.editPurchase(true);
          }
        });
      }
    }
  }

  public completedItemsCheck(): boolean {
    return this.data.purchaseItemsList.every(item => {
      return moment(item.purchaseItem.broadcastEnd).isBefore(this.purchaseForm.get('broadcastStart').value)
    });
  }

  public async audienceDeliveredItemsCheck(): Promise<boolean> {
    const alreadyDeliveredAudience = this.data.purchaseItemsList.every(item => {
      return moment(item.purchaseItem.broadcastStart).isBefore(this.purchaseForm.get('broadcastStart').value)
        && item.purchaseItem.audienceDeliveredUnit > 0;
    });

    if (alreadyDeliveredAudience) {
      return await this.getMinBroadcastStart().then(result => {
        const dateForm = this.purchaseForm.get('broadcastStart').value
        const dateFirstAudience = moment(result).format('YYYY-MM-DD')

        return moment(dateForm).isAfter(moment(dateFirstAudience)) ? true : false
        
      });
    } else {
      return false;
    }
  }

  public getMinBroadcastStart(): Promise<any> {
    return new Promise<any>((dateFirstAudience) => 
      this.purchaseService.getMinBroadcastStart(this.dataPurchase.id).subscribe(
       (response) => {
        dateFirstAudience(response[0].FIRST_DATEAUDIENCE)
       }
     )
    );  
  }

  public invoicedItemsCheck(): Observable<boolean> {
    const observables: Observable<boolean>[] = this.data.purchaseItemsList.map(item => {
      return this.purchaseItemService.itemIsInvoiced(item.id)
    });
  
    return forkJoin(observables).pipe(
      map(results => results.some(result => result === true))
    );
  }

  // function to get items in edit conflict with the new broadcastStart, who are not Canceled and have already some audience delivered
  public getItemsInEditConflict(): any[] {
    return this.data.purchaseItemsList.filter(item => {
      return moment(item.purchaseItem.broadcastStart).isBefore(this.purchaseForm.get('broadcastStart').value)
    });
  }

  public getItemsInEditConflictWithAudience(): any[] {
    return this.data.purchaseItemsList.filter(item => {
      return moment(item.purchaseItem.broadcastStart).isBefore(this.purchaseForm.get('broadcastStart').value)
      && item.purchaseItem.audienceDeliveredUnit > 0;
    });
  }

  private createPurchase(): void {
    this.purchaseService
    .create(this.purchaseForm.value)
    .subscribe(
      purchase => {
        this.loading = false;
        this.snackBar.open(
          'La vente a été créée avec succes',
          null,
          { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
        );

        this.closeDialog(false);
        this.route.navigate([`/purchase/${purchase.id}`]);
      },
      error => this.loading = false
    );
  }

  private editPurchase(isItemsEdit): void {
    this.data.purchase.subscribe(res => {
      this.purchaseService
      .update(this.purchaseForm.getRawValue())
      .subscribe(
        purchase => {
          this.store.dispatch(new GetPurchaseSuccess(purchase));
          this.loading = false;
          this.snackBar.open(
            'La vente a été modifiée avec succès',
            null,
            { duration: AppConstants.snackBarDuration, verticalPosition: 'top' }
          );

          this.closeDialog(isItemsEdit ? true : false);
        },
        error => this.loading = false
      );
    })
    .unsubscribe();
  }

  public displayAdvertiser(value) {
      return typeof value !== 'object' || !value ? value : `${value.name} (${value.id})`;
  }

  public displayProduct(value): string {
    if (typeof value !== 'object' || !value) {
      return value;
    } else {
      if (value.name) {
        if (!this.dataBroadcastStart) { this.dataBroadcastStart = this.nowDate; }
        if (!this.dataBroadcastEnd) { this.dataBroadcastEnd = this.nowDate; }

        this.campaigns$ = this.campaignService.getList({
          product_id: value.id,
          dateStart: this.dataBroadcastStart,
          dateEnd: this.dataBroadcastEnd,
          dealType: this.dataPurchase?.dealType
        });
      }

      return `${value.name} (${value.id})`;
    }
  }

  public changeDealType(event):void {
    this.dealTypesRaw = event.value;
  }
  
  private initLocalValues(data): void {
    this.dataCampaign = data.campaign;
    this.dataBroadcastStart = moment(data.broadcastStart).format('YYYY-MM-DD');
    this.dataBroadcastEnd = moment(data.broadcastEnd).format('YYYY-MM-DD');

    if (data && data.purchase) {
      data.purchase.subscribe(res => {
        this.dataPurchase = res;
      });
    }

    if (this.dataPurchase && this.dataPurchase.campaign) {
      if (!this.dataBroadcastStart) { this.dataBroadcastStart = this.nowDate; }
      if (!this.dataBroadcastEnd) { this.dataBroadcastEnd = this.nowDate; }

      this.campaigns$ = this.campaignService.getList({
        product_id: this.dataPurchase.campaign._embedded.product.id,
        dateStart: this.dataBroadcastStart,
        dateEnd: this.dataBroadcastEnd,
        dealType: this.dataPurchase?.dealType,
      });
    }

    if (this.dataPurchase && this.dataPurchase.product) {
      const product = new Product(this.data.product);
      this.products$ = observableOf([product]);
      this.purchaseForm.get('productTemporary').patchValue(product);
    }
  }

  private startProductAutocomplete(): void {
    this.purchaseForm.get('productTemporary')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(value => typeof value !== 'object')
      .filter(value => value && value.trim().length >= 1)
      .pipe(tap(() => this.loadingProduct = true))
      .pipe(tap(() => this.productAutocompleteElem.nativeElement.click()))
      .subscribe(value => {
        this.ref.markForCheck();
        this.products$ = forkJoin(
          this.productService.getList({name: value}),
          this.productService.getList({id: value}),
        ).pipe(map(data => data[0].concat(data[1])))
        .pipe(tap(() => this.loadingProduct = false));
      });
  }

  private startAdvertiserAutocomplete(): void {
    this.purchaseForm.get('advertiserTemporary')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(value => typeof value !== 'object')
      .filter(value => value && value.trim().length >= 1)
      .pipe(tap(() => this.loadingAdvertiser = true))
      .pipe(tap(() => this.advertiserAutocompleteElem.nativeElement.click()))
      .subscribe(value => {
        this.advertisers$ = forkJoin(
          this.advertiserService.getList({name: value}),
          this.advertiserService.getList({id: value}),
        )
        .pipe(map(data => data[0].concat(data[1])))
        .pipe(tap(() => this.loadingAdvertiser = false));
      });
  }

  private fillFieldsWithCampaign(): void {
      this.purchaseForm.get('campaign')
          .valueChanges
          .startWith(null)
          .debounceTime(400)
          .filter(value => value !== null)
          .subscribe(campaign => {
              if (campaign.advertiser) {
                  this.purchaseForm.patchValue({
                      advertiserTemporary: `${campaign.advertiser.name} (${campaign.advertiser.id})`,
                  });
              }
              if (campaign.agency) {
                  this.purchaseForm.patchValue({
                      agencyTemporary: `${campaign.agency.name} (${campaign.agency.id})`,
                  });
              }
          });

      if (this.dataPurchase && this.dataPurchase.step.id > 2) {
          this.disablePurchaseFormFields(this.purchaseForm, this.dataPurchase.step.id);
      }
  }

  private checkFsaExistency(control: AbstractControl) {
    return this.fsaService
      .checkExistency({id: control.value})
      .pipe(map(res => {
        return res ? null : { fsaNotExist: true };
      }));
  }

  private onChanges() {
      this.purchaseForm.get('parrainage').valueChanges
          .subscribe(parrainageValue => {
              if (parrainageValue) {
                  this.purchaseForm.get('folderNum').reset();
                  this.purchaseForm.get('folderNum').disable();
              } else {
                  this.purchaseForm.get('folderNum').enable();
              }
          });
  }

  private disablePurchaseFormFields(purchaseForm, stepId) {
    purchaseForm.controls.advertiserTemporary.disable();
    purchaseForm.controls.campaign.disable();
    purchaseForm.controls.productTemporary.disable();
    purchaseForm.controls.agencyTemporary.disable();
    stepId == 6 ? purchaseForm.controls.broadcastStart.disable() : purchaseForm.controls.broadcastStart.enable();
    // It's not possible to edit a purchase with invoiced purchaseItems 
    this.invoicedItemsCheck().subscribe(isInvoiced => {
      if (isInvoiced) {
        purchaseForm.controls.broadcastStart.disable()
      }
    });
  }

  private initProgrammaticValues(purchase: Purchase) {
    this.isProgrammatic = purchase?.dealType == AppConstants.purchase.programmatic.isProgrammatic;
    this.dateStartLocked = purchase.id && this.isProgrammatic &&  moment(purchase.broadcastStart).isBefore(moment());
    if (this.isProgrammatic) {
      if (this.dateStartLocked) {
        this.purchaseForm.controls['broadcastStart'].disable();
        this.purchaseForm.controls['broadcastStart'].setValue(purchase.broadcastStart);
        this.purchaseForm.controls['broadcastEnd'].setValue(purchase.broadcastEnd);
        this.minDate = null;
        this.minMaxDate = moment();
      } else {
        this.minDate = moment();
        this.minMaxDate = moment(purchase.broadcastEnd);
      }
    }
  }

  private initDealTypes() {
    this.purchaseService.getTypes().subscribe(
      (response) => {
          const dealTypes = response['_embedded']['sell_type'];
          const programmaticDealType = dealTypes.find(
            (type) => ( type.label === 'PROGRAMMATIC' && type.enabled )
          );
          if ((this.dataPurchase?.dealType === 1) || programmaticDealType) {
            this.dealTypes = [...this.purchaseService.types, { id: 1, label: 'Achat Programmatique' }];
          } else {
            this.dealTypes = this.purchaseService.types;
          }
          this.dealTypesRaw = this.dataPurchase?.dealType ?? this.dealTypes[0].id;
          this.purchaseForm.get('dealType').setValue(this.dealTypesRaw);
        },
      error => console.error('Sell Types', error)
    );
  }
}
