import {Component, OnInit, ViewChild,} from '@angular/core';
import {BuyerService} from '../../../core/service/buyer.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {EditComponent} from '../edit/edit.component';
import {AppConstants} from '../../../app.constants';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatPaginator} from '@angular/material/paginator';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-resource-buyer-list',
  templateUrl: './buyer-list.component.html',
  styleUrls: ['./buyer-list.component.scss'],
})

export class BuyerListComponent implements OnInit {

  public displayedColumns: string[] = ['tradingDesk', 'businessName', 'action'];
  public searchForm: FormGroup;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  protected buyers;
  protected loadingData;

  constructor(
    protected buyerService: BuyerService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {
  }

  public ngOnInit(): void {
    this.initForm();
    this.getBuyers();
  }

  protected getBuyers($event = null) {
    this.loadingData = true;
    this.buyerService.searchBuyers({
      sortActive: this.sort?.active || 'tradingDesk',
      sortDirection: this.sort?.direction || 'asc',
      itemPerPage: this.paginator?.pageSize || 50,
      page: !this.paginator?.pageIndex ? 1 : this.paginator?.pageIndex + 1,
      search: this.searchForm.get('search')?.value
    }).subscribe(
      (response: any) => {
        this.buyers = [];
        for (const [key, item] of Object.entries(response.items)) {
          this.buyers.push(item);
        }

        if (response.total_count) {
          this.paginator.length = response.total_count;
        }
        this.loadingData = false;
      },
      (error) => {
        this.snackBar.open(
          'Le chargement de la des Trading desk a échoué.',
          null,
          {
            duration: AppConstants.snackBarDuration,
            verticalPosition: 'top',
            panelClass: ['chip-error']
          });
        this.loadingData = false;
      }
    );
  }

  protected sortItems(event) {
    this.sort.active = event.active;
    this.sort.direction = event.direction;
    this.getBuyers();
  }

  protected editBusinessName($event, element: any) {
    event.stopPropagation();
    this.dialog.open(EditComponent, {
      width: '1000px',
      disableClose: true,
      data: {
        modalTitle: 'Consulter/modifier le trading desk: ' + element.tradingDesk,
        buyer: element,
        modalOnClose: () => {
          this.getBuyers();
        }
      }
    });
  }

  protected loadMore($event) {
    this.getBuyers();
  }

  protected initForm(): void {
    this.searchForm = this.fb.group({
      search: [''],
    });

    this.searchForm.get('search').valueChanges.subscribe(value => {
        this.loadingData = true;
        this.buyerService.searchBuyers({
          sortActive: this.sort?.active || 'tradingDesk',
          sortDirection: this.sort?.direction || 'asc',
          search: value
        }).subscribe(
          (response: any) => {
            this.buyers = [];
            for (const [key, item] of Object.entries(response.items)) {
              this.buyers.push(item);
            }
            if (response.total_count) {
              this.paginator.length = response.total_count;
            }
            this.loadingData = false;
          }
        );
      }
    );
  }

  protected clearSearch() {
    this.searchForm.get('search')?.setValue('');
  }
}
