import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { AppConstants } from '../../../app.constants';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PurchaseItem} from '../../../core/model/purchase-item.model';
import {PurchaseItemDaypartService} from '../../../core/service/purchase-item-daypart.service';
import {PurchaseItemService} from '../../../core/service/purchase-item.service';
import { PurchaseItemDaypart } from '../../../core/model/purchase-item-daypart.model';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import { PlanningAddComponent } from './planning-add/planning-add.component';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { GetDaypartsSuccess, RemoveDaypart,AppState } from '../../../store';

@Component({
  selector: 'app-display-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss'],
  providers: [PurchaseItemService, PurchaseItemDaypartService]
})

export class PlanningComponent implements OnInit, OnDestroy {
  public purchaseItemDaypart$;
  public dayparts: any[] = [];
  private modalTitle:string;
  public planningForm: FormGroup;
  public loading;
  public deleting: boolean = false;

  @Input() purchaseItemId: number = null;
  @Input() dispoType: number;
  @Input() isProgrammatic: boolean = false;

  constructor(
    private _fb: FormBuilder,
    public daypartService: PurchaseItemDaypartService,
    private purchaseItemService: PurchaseItemService,
    private route: Router,
    public dialogRef: MatDialog,
    private snackBar: MatSnackBar,
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.planningForm = this._fb.group({
      userTimezone: '1',
    });

    this.loading = true;
    this.daypartService.get(this.purchaseItemId)
      .subscribe(data => {
        this.loading = false;
        this.store.dispatch(new GetDaypartsSuccess(data));
      }, error => {
        this.loading = false;
      });

    this.purchaseItemDaypart$ = this.store.select('daypartList')
      .subscribe(dayparts => {
        if (dayparts) {
          this.dayparts = dayparts[0];
          if (this.dayparts && this.dayparts.length > 0) {
            this.planningForm.get('userTimezone').patchValue(this.dayparts[0]['userTimezone']);
          }
        }
    });
  }

  ngOnDestroy() {
    if (this.purchaseItemDaypart$) {
      this.purchaseItemDaypart$.unsubscribe();
    }
  }

  addEditPeriods(edit: boolean = false, id: number|null= null) {
    let modalBtnValid = '';
    if(edit == false) {
      this.modalTitle = 'Ajout de créneaux';
      modalBtnValid = 'Valider';
    } else {
      this.modalTitle = 'Editer un créneaux';
      modalBtnValid = 'Modifier';
    }
    const dialogRef = this.dialogRef.open(PlanningAddComponent, {
        width: '500px',
        disableClose: true,
        data: {
          modalTitle: this.modalTitle,
          modalBtnCancel: 'Annuler',
          modalBtnValid: modalBtnValid,
          purchaseItemId: this.purchaseItemId,
          daypartId: id,
          dispoType: this.dispoType,
          userTimezone: this.planningForm.get('userTimezone').value,
          edit: edit
        }
      });
  }

  delete(i:number, index:number) {
    this.deleting = true;
    this.daypartService.delete(i).subscribe(
      daypart => {
        this.store.dispatch(new RemoveDaypart(index));
        this.deleting = false;
      },
      error => this.deleting = false
      );
  }

  public closeDialog(): void {
      this.dialogRef.closeAll();
  }

}
