import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpClient} from "@angular/common/http";

@Injectable()

export class CreativeIdService {

  private route: string = '/digital/freewheel-creative';
  private routeTv: string = '/digital/freewheel-update-tv';
  private url = environment.adspace_api_base_url + this.route;

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  public getCreativeId(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(this.url)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public executeFwTv(): Observable<any> {
    return Observable.create(observer => {
      this.httpService
        .get(environment.adspace_api_base_url + this.routeTv)
        .subscribe(
          response => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  public updateCreateInstance(): Observable<any> {
    const url = environment.adspace_api_base_url + '/digital/update-create-instance';
    return this.httpService.get(url);
  }

  /**
   * @param error
   * @param {string} routeRedirect
   */
  private catchError(error: any, routeRedirect: string = null): void {
    switch (error.status) {
      case 404:
        this.snackBar.open(
          'Aucun creative id  n\'a été trouvé',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
      default:
        this.snackBar.open(
          'Une erreur est survenue dans la récupération des creatives id',
          null,
          { duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    if (routeRedirect) { this.router.navigate([routeRedirect]); }

    return error;
  }
}
