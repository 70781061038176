import {Product} from './product.model';
import {Advertiser, JsonAdvertiser} from './advertiser.model';
import {Agency} from './agency.model';
import {PurchaseOrigin} from './purchase-origin.model';
import {Ut} from './ut.model';
import {Customer} from './customer.model';

export interface JsonCampaignSegmental {
  id: number;
  name: string;
  num_campaign: number;
  year?: number;
  dateStart?: Date;
  dateEnd?: Date;
  product?: Product;
  advertiser?: JsonAdvertiser;
  agency?: Agency;
  channelAgreement?: string;
  purchaseOrigin?: PurchaseOrigin;
  commercial?: Ut;
  idFw?: number;
  nummode?: number;
  trafic?: Ut;
  _embedded?: any;
}

export class CampaignSegmental {

  public id?: number;
  public name?: string;
  public numCampaign?: number;
  public year?: number;
  public dateStart?: Date;
  public dateEnd?: Date;
  public product?: Product;
  public advertiser?: Advertiser;
  public agency?: Agency;
  public channelAgreement?: string;
  public purchaseOrigin?: PurchaseOrigin;
  public commercial?: Ut;
  public idFw?: number;
  public nummode?: number;
  public trafic?: Ut;

  constructor(jsonCampaignSegmental: JsonCampaignSegmental) {
    this.id = jsonCampaignSegmental.id;
    this.name = jsonCampaignSegmental.name;
    this.numCampaign = jsonCampaignSegmental.num_campaign;
    this.year = jsonCampaignSegmental.year;
    this.dateStart = jsonCampaignSegmental.dateStart;
    this.dateEnd = jsonCampaignSegmental.dateEnd;
    this.channelAgreement = jsonCampaignSegmental.channelAgreement;
    this.purchaseOrigin = jsonCampaignSegmental.purchaseOrigin;
    this.idFw = jsonCampaignSegmental.idFw;
    this.nummode = jsonCampaignSegmental.nummode;

    if (jsonCampaignSegmental._embedded && jsonCampaignSegmental._embedded.product) {
      this.product = new Product(jsonCampaignSegmental._embedded.product);
    } else if (jsonCampaignSegmental.product) {
      this.product = new Product(jsonCampaignSegmental.product);
    }


    if (jsonCampaignSegmental._embedded && jsonCampaignSegmental._embedded.advertiser) {
      this.advertiser = new Advertiser(jsonCampaignSegmental._embedded.advertiser);
    } else {
      this.advertiser = new Advertiser(jsonCampaignSegmental.advertiser);
    }


    if (jsonCampaignSegmental._embedded && jsonCampaignSegmental._embedded.agency) {
      this.agency = new Agency(jsonCampaignSegmental._embedded.agency);
    } else if (jsonCampaignSegmental.agency) {
      this.agency = new Agency(jsonCampaignSegmental.agency);
    }

    if (jsonCampaignSegmental._embedded && jsonCampaignSegmental._embedded.commercial) {
      this.commercial = new Customer(jsonCampaignSegmental._embedded.commercial);
    } else if (jsonCampaignSegmental.commercial) {
      this.commercial = new Ut(jsonCampaignSegmental.commercial);
    }

    if (jsonCampaignSegmental._embedded && jsonCampaignSegmental._embedded.trafic) {
      this.trafic = new Customer(jsonCampaignSegmental._embedded.trafic);
    } else if (jsonCampaignSegmental.trafic) {
      this.trafic = new Ut(jsonCampaignSegmental.trafic);
    }
  }
}
