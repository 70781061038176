import {distinctUntilChanged} from 'rxjs/operators';
import {Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {AppConstants} from '../../../app.constants';
import {PurchaseItemService} from '../../../core/service/purchase-item.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {AppState, AddEditDisplayVideo, RemoveForecast} from '../../../store/index';
import {MessageDialogComponent} from '../../shared/message-dialog/message-dialog.component';
import {PurchaseFilmDialogComponent} from '../../purchase-film-dialog/purchase-film-dialog.component';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {dateItemValidator} from '../../../core/validator/date-item.validator';

import {Film, JsonFilm} from '../../../core/model/film.model';
import {Moment} from 'moment';
import * as moment from 'moment';
import {PurchaseFilmLiveDialogComponent} from '../../purchase-film-live-dialog/purchase-film-live-dialog.component';
import { PurchaseSyncCreativeDialogComponent } from '../../../purchase/purchase-creative-dialog/purchase-sync-creative-dialog.component';

@Component({
  selector: 'app-purchase-item-detail',
  templateUrl: './purchase-item-detail.component.html',
  styleUrls: ['./purchase-item-detail.component.scss']
})
export class PurchaseItemDetailComponent implements OnInit {

  @Input() order: number = 0;
  @Input() item: any;
  @Input() purchase: any;
  @Input() purchaseId: number;
  @Output() itemEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() parentComp: any;
  public editing: boolean = false;
  public editingMainData: boolean = false;
  public editingTitle: boolean = false;
  public editingDates = false;
  public editForm: FormGroup;
  public saving: boolean;
  public title: string;
  public duplicating: boolean = false;
  public deleting: boolean = false;
  public active: boolean = true;

  public forecasting: boolean = false;
  public forecasted: boolean = false;
  public forecastInProgess: boolean = false;
  public forecastResult: any;
  public errorSync: boolean = false;
  public syncMsg: string;
  public synced: boolean = false;
  public errorForecast: boolean = false;
  public showForecast: boolean = false;
  public forecast$: Observable<any>;
  public purchaseStep: number = 0;
  public isFilmOk: boolean = false;
  public isPrereserve: boolean = false;
  public endDatePrereserve: string = '';
  public prereserving: boolean = false;

  public showFormatEditBtn = false;
  public showRangseEditBtn = false;
  public showKeywordsEditBtn = false;
  public showPeriodsEditBtn = false;

  public rightPanelRowSpan = 5;

  public showForcast = false;

  public currentPurchaseItemFormat: string = '';

  constructor(private purchaseItemService: PurchaseItemService,
              private snackBar: MatSnackBar,
              private route: Router,
              private store: Store<AppState>,
              public dialog: MatDialog,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.initForm();
    this.initForecast();
    this.initValueChanges();
    this.purchaseStep = this.purchase._embedded.step.step_num;
    this.isFilmOk = this.checkFilms(this.item.films || null);
    this.initPrereservationValues(this.item.purchaseItem);
  }

  private checkFilms(films): boolean {
    if (!films) {
      return false;
    }

    let valid = true;
    films.forEach(film => {
      film = new Film(film);

      if (!(film.isAllEncoded() && film.isAllProvisioning() && film.isAllAcquitted())) {
        valid = false;
      }
    });

    return valid;
  }

  private initForm(): void {
    this.title = (!this.hasTitle(this.item) ?
      'Dispositif ' + this.getItemTitle(this.item) + ' ' + (this.item.purchaseItem.id) :
      this.item.purchaseItem.title);

    this.editForm = this.fb.group({
        title: [this.title,
          [
            Validators.required,
            Validators.maxLength(150)
          ]],
        step: [this.item.step],
        broadcastStart: [this.item.purchaseItem.broadcastStart],
        broadcastEnd: [this.item.purchaseItem.broadcastEnd],
        type: [this.item.purchaseItem.type],
        id: [this.item.purchaseItem.id],
        purchaseId: [this.purchaseId],
        technicalElemReceive: [this.item.purchaseItem.technicalElemReceive],
        screenshotSend: [this.item.purchaseItem.screenshotSend]
      },
      {validator: dateItemValidator});
    if (this.item.step && this.item.step.id > 1) {
      this.editForm.controls.broadcastStart.disable();
    }
  }

  public onClickRating(event): void {
  }

  public editItem(): void {
    this.editing = !this.editing;
    if (!this.editing) {
      this.editingMainData = false;
      this.rightPanelRowSpan = 5;
    } else {
      this.rightPanelRowSpan = 13;
    }
  }

  public duplicate(): void {
    this.duplicating = true;
    this.saving = true;
    this.purchaseItemService.duplicate(this.item.id)
      .subscribe(() => {
        this.duplicating = false;
        this.saving = false;
        this.itemEvent.emit({operation: 'reload'});
      }, (error) => {
        this.deleting = false;
      });
  }

  public delete(): void {
    this.saving = true;
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        modalTitle: '',
        modalBtnCancel: 'Annuler',
        modalBtnValid: 'Valider',
        confirmQuestion: false,
        message: 'Êtes-vous sûr de vouloir supprimer ce dispositif ?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'save') {
        this.deleting = true;
        this.purchaseItemService.delete(this.item.id)
          .subscribe((data) => {
            this.deleting = false;
            this.saving = false;
            this.active = false;
            this.itemEvent.emit({operation: 'reload'});
          }, (error) => {
            this.saving = false;
            this.deleting = false;
          });
      } else {
        this.saving = false;
      }
    });
  }

  /**
   * Show popup in env dev/recette
   */
  public forecast(): void {
    if (this.parentComp.isValidPurchaseItems()) {
      this.sendForecast();
    }
  }

  /**
   * @param item
   * return Observable<any>
   */
  public syncFilms(item): Observable<any> {
    const dataToPass = { targetId: item.id, targetService: 'purchaseItemService' };
    const dialogRef = this.dialog.open(PurchaseSyncCreativeDialogComponent, {
      width: '600px',
      disableClose: true,
      data: dataToPass
    });

    return dialogRef.afterClosed();
  }

  /**
   * Send forecast to adserver
   * @param campainTest
   * @returns Observable<any>
   */
  public sendForecast(campainTest: boolean = false): void {
    this.forecasting = true;
    this.forecasted = false;
    this.errorForecast = false;
    this.errorSync = false;
    this.synced = false;
    this.saving = true;

    // To reactivate leter, when AdOps will be ready
    // if (this.item.films && this.item.films.length > 0) {
    //   this.syncFilms(this.item).subscribe({
    //     error: error => {
    //       console.error('Error in syncro Creative: ', error);
    //     }
    //   });
    // }

    this.purchaseItemService.forecast(this.item.id, campainTest)
      .subscribe((rs) => {
        this.forecasting = false;
        this.saving = false;

        if (!rs) return;

        // demand forecast sent
        if (rs['forecast'] == AppConstants.forecast.fw_fc_sent) {
          this.synced = true;
          this.store.dispatch(new RemoveForecast(this.item.id));
          this.purchaseItemService.getForecast(this.item.id);
          this.listenForecast();
        } else {
          if (rs['sync'] == AppConstants.forecast.fw_sync_error) {
            this.errorSync = true;
            this.syncMsg = rs['rs'];
          } else {

            this.forecastInProgess = (rs['forecast'] == AppConstants.forecast.fw_fc_in_progress);
            if (rs['forecast'] === AppConstants.forecast.fw_fc_error || rs['forecast'] === AppConstants.forecast.dfp_fc_error) {
              this.forecastResult = rs['rs'];
              this.forecasted = true;
            }
          }
        }
      }, (error) => {
        this.forecasting = false;
      });
  }

  /**
   * Liste forecast in state after send forecast demand
   */
  private listenForecast(): void {
    if (this.forecast$ !== undefined) return;

    this.forecast$ = this.store.select('forecast');

    this.forecast$.subscribe((res) => {
      if (res.length > 0) {
        for (const f of res) {
          if (f['purchaseItemId'] == this.item.purchaseItem.id
            && f['forecast'] == AppConstants.forecast.fw_fc_done) {
            this.forecasting = false;
            this.errorForecast = false;
            this.errorSync = false;
            this.synced = false;
            this.forecastInProgess = false;

            this.forecasted = true;
            this.openSnackBar(
              `Le forecast du dispositif ${this.item.purchaseItem.title} est disponible`,
              null
            );
            this.forecastResult = f['rs'];
            break;
          }
        }
      }
    });
  }

  public editTitle(): void {
    this.editingTitle = true;
  }

  public isDisplayType(item: any): boolean {
    return (item.purchaseItem.type == 1);
  }

  public hasTitle(item: any): boolean {
    return !!(item.purchaseItem.title);
  }

  public redirect(item, block: number = 100): void {
    const typeTitle = '/' + this.getItemTitle(item) + '/';
    let url = '/purchase/' + this.purchaseId + typeTitle
      + item.purchaseItem.id;

    if (block !== 100) {
      url = url + '/' + block;
    }

    this.route.navigate([url]);
  }

  public saveTitleItem(): void {
    if (this.editForm.valid) {
      const data = this.editForm.getRawValue();
      this.saving = true;
      this.purchaseItemService.patch(this.item.purchaseItem.id, data)
        .subscribe(
          purchaseItem => {
            this.saving = false;
            this.store.dispatch(new AddEditDisplayVideo(purchaseItem));
            this.editingMainData = false;
          },
          HttpErrorResponse => {
            this.saving = false;
            const msg = HttpErrorResponse.error.detail ? HttpErrorResponse.error.detail :
              'Impossible de modifier ce dispositif, veuillez réessayer ultérieurement';
            this.openSnackBar(
              msg,
              null
            );
          }
        );
    }
  }

  public openSnackBar(message: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
    const snackBarRef$ = this.snackBar.open(
      message,
      action,
      {
        duration: AppConstants.snackBarDuration,
        verticalPosition: 'top',
      });
    return snackBarRef$;
  }

  public isOps(item: any): boolean {
    return (item.purchaseItem.type === AppConstants.typeOps);
  }

  public isOpsOrdonationTechCost(item: any): boolean {
    return item.purchaseItem.type === AppConstants.typeOps ||
      item.purchaseItem.type === AppConstants.typeDonationTechCost;
  }

  public isdonationTechCost(item: any): boolean {
    return item.purchaseItem.type === AppConstants.typeDonationTechCost;
  }

  /**
   * @param item
   */
  public isSponsorship(item: any): boolean {
    if (item.diffusionMode) {
      return (item.diffusionMode.id === AppConstants.sponsorship.display
        || item.diffusionMode.id === AppConstants.sponsorship.video);
    }
    return false;
  }

  public isServerBusy(): boolean {
    return this.deleting || this.duplicating || this.forecasting || this.prereserving;
  }

  private getItemTitle(item): string {
    const type = item.purchaseItem.type;
    switch (type) {
      case AppConstants.typeVideo:
        return 'video';
      case AppConstants.typeDisplay:
        return 'display';
      case AppConstants.typeSegmentalTv:
        return 'segmental-tv';
      case AppConstants.typeDonationTechCost:
        return 'donation-tech-cost';
      default:
        return 'ops';
    }
  }

  /**
   * Show popup for test env
   */
  private pushTest(): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        modalTitle: '',
        modalBtnCancel: 'Non',
        modalBtnValid: 'Oui',
        confirmQuestion: false,
        message: 'Voulez-vous forcer à pousser sur la campagne test ?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'save') {
        this.sendForecast(true);
      }
    });
  }

  /**
   * Class of bandeau
   * @param f
   */
  public getClassForecast(f: any): string {
    if (f && f['message']) {
      if (f['message']['errors']) {
        return 'forecast-error';
      } else if (parseInt(f['message']['forecast_final_delivery_rate']) < 100) {
        return 'forecast-warning';
      }
    }

    return 'forecast-success';
  }

  /**
   * @param forecastResult
   */
  public getForecastDate(forecastResult: any): string {
    if (forecastResult && forecastResult['message']) {
      return forecastResult['message'] && forecastResult['message']['run_time']
        ? forecastResult['message']['run_time']
        : (forecastResult['dateSync'] && forecastResult['dateSync']['date']
          ? forecastResult['dateSync']['date']
          : forecastResult['date_sync']['date']);
    }

    return '';
  }

  /**
   * Only get forecast result when forecast demand sent
   */
  private initForecast(): void {
    if (this.item.syncAds) {
      let getForecastResult: boolean = false;
      let hadForecastResult: boolean = false;

      for (const syncItem of this.item.syncAds) {
        if (syncItem['typeSync'] === 'fw_send_forecast' || syncItem['typeSync'] === 'dfp_forecast') {
          getForecastResult = !!syncItem['status'];

          if (!getForecastResult) {
            this.forecasted = true;
            this.forecastResult = syncItem;
            break;
          }
        }

        if ((syncItem['typeSync'] === 'fw_receive_forecast' || syncItem['typeSync'] === 'dfp_forecast')
          && syncItem['status']) {
          hadForecastResult = true;
          this.forecasted = true;
          this.forecastResult = syncItem;
        }
      }

      if (getForecastResult && !hadForecastResult) {
        this.purchaseItemService.getForecast(this.item.id);
        this.listenForecast();
      }
    }
  }

  private initValueChanges() {
    const data = this.editForm.value;
    const technicalElemReceiveControl = this.editForm.get('technicalElemReceive');
    const screenshotSendControl = this.editForm.get('screenshotSend');

    technicalElemReceiveControl
      .valueChanges.pipe(
      distinctUntilChanged())
      .subscribe((checked: boolean) => {
        this.saving = true;
        this.editForm.get('screenshotSend').disable({emitEvent: false});
        this.patchPurchaseItem({
          technicalElemReceive: checked,
          screenshotSend: this.editForm.get('screenshotSend').value,
        })
          .subscribe(
            rs => this.itemEvent.emit({operation: 'reload-alerts'}),
            error => this.editForm.get('screenshotSend').patchValue(!checked, {emitEvent: false}),
            completed => {
              this.editForm.get('technicalElemReceive').enable({emitEvent: false});
              this.saving = false;
            }
          );
      });

    screenshotSendControl
      .valueChanges.pipe(
      distinctUntilChanged())
      .subscribe((checked: boolean) => {
        this.saving = true;
        this.editForm.get('technicalElemReceive').disable({emitEvent: false});
        this.patchPurchaseItem({
          screenshotSend: checked,
          technicalElemReceive: this.editForm.get('technicalElemReceive').value,
        })
          .subscribe(
            error => this.editForm.get('technicalElemReceive').patchValue(!checked, {emitEvent: false}),
            completed => {
              this.editForm.get('technicalElemReceive').enable({emitEvent: false});
              this.saving = false;
            }
          );
      });
  }

  private patchPurchaseItem(data) {
    return Observable.create(observer => {
      this.purchaseItemService.patch(this.item.purchaseItem.id, data, ['films'])
        .subscribe(
          purchaseItem => {
            this.store.dispatch(new AddEditDisplayVideo(purchaseItem));
            observer.next(true);
          },
          HttpErrorResponse => {
            const msg = HttpErrorResponse.error.detail ? HttpErrorResponse.error.detail :
              'Impossible de modifier ce dispositif, veuillez réessayer ultérieurement';
            this.openSnackBar(
              msg,
              null
            );
            observer.error();
          }
        );
    });
  }

  public openFilmDialog() {
    const dialogRef = this.dialog.open(PurchaseFilmDialogComponent, {
      width: '1000px',
      disableClose: true,
      data: {
        item: this.item,
        purchase: this.purchase
      }
    });
  }

  public openFilmLiveDialog() {
    const dialogRef = this.dialog.open(PurchaseFilmLiveDialogComponent, {
      width: '1000px',
      disableClose: true,
      data: {
        item: this.item,
        purchase: this.purchase
      }
    });
  }

  public isDisplay(item: any): boolean {
    return (item.purchaseItem.type === AppConstants.typeDisplay);
  }

  public isVideo(item: any): boolean {
    return (item.purchaseItem.type === AppConstants.typeVideo);
  }

  public isSegmentalTv(item: any): boolean {
    return (item.purchaseItem.type === AppConstants.typeSegmentalTv);
  }

  public isDealProgrammatic(item: any): boolean {
    return (item?.dealProgrammmatic);
  }

  public isForecastComplete(): boolean {
    if (this.forecastResult && this.forecastResult['message']) {
      const itemForecastDeliveryRate = parseInt(this.forecastResult['message']['forecast_final_delivery_rate']);
      return (itemForecastDeliveryRate === 100);
    }
    return false;
  }

  public prereservation(): void {
    if (this.parentComp.isValidPurchaseItems()) {
      this.errorSync = false;

      this.prereserving = true;
      this.purchaseItemService.preReserve(!this.isPrereserve, this.item.id)
        .subscribe((result) => {
          if (!result) return;

          if (result['sync'] == AppConstants.forecast.fw_sync_error) {
            this.errorSync = true;
            this.syncMsg = result['result'];
          } else {
            this.initPrereservationValues(result['purchaseItem'].purchaseItem);
            this.store.dispatch(new AddEditDisplayVideo(result['purchaseItem']));
          }

          this.prereserving = false;
        });
    }
  }

  public isNotValidItem(): boolean {
    if (this.parentComp.itemsNotValid) {
      const itemsNotValid = this.parentComp.itemsNotValid.split(',');
      for (let i = 0; i < itemsNotValid.length; i++) {
        if (parseInt(itemsNotValid[i]) == parseInt(this.item.purchaseItem.id)) return true;
      }
    }
    return false;
  }

  private initPrereservationValues(purchaseItem) {
    this.prereserving = false;
    this.isPrereserve = purchaseItem.isPrereserve;
    if (this.isPrereserve) {
      this.endDatePrereserve = moment(purchaseItem.endDatePrereserve.date)
        .format('DD/MM/YYYY')
        .toString();
    }
  }

  public getIdPeriode(item) {
    this.snackBar.open(
      'Dispositif : "' + item.purchaseItem.title + '" - Id Periode : "' + item.purchaseItem.idperiode + '".',
      null,
      {duration: 10000, verticalPosition: 'top'}
    );
  }

  getExternalDealId(item: any) {
    this.snackBar.open(
      'External Deal Id : "' + item.purchaseItem.externalDealId + '".',
      null,
      {duration: 10000, verticalPosition: 'top'}
    );
  }
}
