import { Component, OnInit } from '@angular/core';
import {PurchaseItemDetailComponent} from '../purchase-item-detail/purchase-item-detail.component';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-purchase-item-detail-programmatic',
  templateUrl: './purchase-item-detail-programmatic.component.html',
  styleUrls: ['./purchase-item-detail-programmatic.component.scss']
})
export class PurchaseItemDetailProgrammaticComponent extends PurchaseItemDetailComponent implements OnInit {

  protected $barProgressionTrendClass = 'mat-progress-bar-trend-programmatic-value'

  ngOnInit() {
    this.initForm();
    this.initProgrammaticItemValues();
    this.initForecast();
    this.initValueChanges();
    this.purchaseStep = this.purchase._embedded.step.step_num;
    this.isFilmOk = this.checkFilms(this.item.films || null);
    this.initPrereservationValues(this.item.purchaseItem);
    this.initFilmEncodingState() ;
  }

  public initProgrammaticItemValues(): void {
    const broadcastStart = this.item.dealProgrammatic?.broadcastStart.date;
    this.isDeletable = moment(broadcastStart).isAfter(moment());
    this.dateStartLocked = this.item?.id && moment(broadcastStart).isBefore(moment().subtract(1, 'days')) ? true : null;
    if (this.dateStartLocked) {
      this.editForm.controls['broadcastStart'].disable();
      this.minDate = null;
      this.minMaxDate = moment();
    } else {
      this.minDate = moment();
      this.minMaxDate = null;
    }
  }
}
