import {Component, OnInit} from '@angular/core';
import {KeywordService} from '../../core/service/keyword.service';
import {RegionService} from '../../core/service/region.service';
import {VideoService} from '../../core/service/video.service';
import {AppConstants} from '../../app.constants';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AudSegmentService} from '../../core/service/aud-segment.service';
import {CreativeIdService} from '../../core/service/creative-id.service';
import {TvCampaignService} from '../../core/service/tv-campaign.service';
import {LiveTrackingService} from '../../core/service/live-tracking.service';
import {FsaService} from '../../core/service/fsa.service';
import {PackRuleCriteriaService} from '../../core/service/pack-rule-criteria.service';
import {AlertService} from '../../core/service/alert.service';
import {OrangeService} from '../../core/service/orange.service';
import {PurchaseItemService} from '../../core/service/purchase-item.service';
import {MogadorService} from '../../core/service/mogador.service';
import {CroiseTvDigitalService} from '../../core/service/croise-tv-digital.service';
import {CampaignService} from '../../core/service/campaign.service';
import {DetailSpotByDayService} from '../../core/service/detail-spot-by-screen.service';
import {MeteoFranceService} from '../../core/service/meteo-france.service';
import {CreativeManagementService} from '../../core/service/creative-management.service';
import {ItemOfferRangeCategoryService} from '../../core/service/item-offer-range-category.service';
import {FreewheelService} from '../../core/service/freewheel.service';
import {MultispotPushService} from '../../core/service/multispot-push.service';
import {AdUnitService} from '../../core/service/ad-unit.service';
import {KantarService} from '../../core/service/kantar.service';
import {CollectionRubriqueService} from "../../core/service/collection-rubrique.service";
import {VideosRubriquesService} from '../../core/service/videos-rubriques.service';
import {BuyerService, IBuyerServiceConfigInterface} from '../../core/service/buyer.service';
import {CrossMediaService} from '../../core/service/cross-media.service';
import {EquativService} from '../../core/service/equativ.service';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {error} from 'console';
import {AudienceService} from '../../core/service/audience.service';

@Component({
  selector: 'app-resource-content',
  templateUrl: './resource-content.component.html',
  styleUrls: ['./resource-content.component.scss']
})
export class ResourceContentComponent implements OnInit {
  public updatingKeywords = false;
  public updatingRegions = false;
  public updatingVideoGroup = false;
  public updatingAudiences = false;
  public updatingCreativeId = false;
  public updatingCampaign = false;
  public updatingFwAudience = false;
  public ingestingCriteria = false;
  public updatingLiveTracking = false;
  public updatingInjestTv5Monde = false;
  public updatingCollectionRubrique = false;
  public updatingVideosRubriques = false;
  public updatingFsa = false;
  public creatingAlerts = false;
  public purgingVideos = false;
  public pushingTvAdrPurchaseItem = false;
  public getFwIdVideos = false;
  public isIngestMeteoFranceLoading = false;
  public isIngestMeteoFranceUvLoading = false;
  public from = 20000000;
  public to: number;
  public creatingTvSegCampaign = false;
  public pushingCreativeManagementToFW = false;
  public refreshingItemOfferRangeCategories = false;
  public updatingFwCampaignTestId = false;
  public fwCampaignId = null;
  public isPushingSpotCombinationsToAWS = false;
  public isPushingNbAdsDurationsAWS = false;
  public updatingAdUnit = false;
  public isSendingFileKantar = false;
  public isSendingMailKantarFilesExist = false;
  public pushAudiencesitemsFw = false;
  public typePackLabelRubrique: string = null;
  public typePackLabelVideo: string = null;
  public syncBuyerConfig: IBuyerServiceConfigInterface =  null;


  constructor(
    private keywordService: KeywordService,
    private regionService: RegionService,
    private videoService: VideoService,
    private audService: AudSegmentService,
    private creativeIdService: CreativeIdService,
    private snackBar: MatSnackBar,
    private tvCampagneService: TvCampaignService,
    private liveTrackingService: LiveTrackingService,
    private fsaService: FsaService,
    private collectionRubriqueService: CollectionRubriqueService,
    private criteriaService: PackRuleCriteriaService,
    private videosRubriquesService: VideosRubriquesService,
    private alertService: AlertService,
    private orangeService: OrangeService,
    private purchaseItemService: PurchaseItemService,
    private mogadorService: MogadorService,
    private croiseTvDigitalService: CroiseTvDigitalService,
    private campaignService: CampaignService,
    private detailSpotByDayService: DetailSpotByDayService,
    private meteoFranceService: MeteoFranceService,
    private creativeManagementService: CreativeManagementService,
    private itemOfferRangeCategoryService: ItemOfferRangeCategoryService,
    private freewheelService: FreewheelService,
    private multispotPushService: MultispotPushService,
    private adUnitService: AdUnitService,
    private kantarService: KantarService ,
    private buyerService: BuyerService,
    private crossMediaService: CrossMediaService,
    private equativService: EquativService,
    private httpService: HttpClient,
    private audienceService: AudienceService,
  ) {
  }

  protected  crossMediaSnackbarSuccessDuration = 5000;

  ngOnInit() {
    this.syncBuyerConfig = this.buyerService.getConfig();
  }

  public ingestCriteria(): void {
    this.ingestingCriteria = true;
    this.criteriaService.ingestCriteria().subscribe(
      response => {
        this.ingestingCriteria = false;
        this.snackBar.open(
          'Le processus de mise à jour des critères vidéo est terminé.',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      }, error => {
        this.ingestingCriteria = false;
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      });
  }

  public updateKeywordsDfp(): void {
    this.updatingKeywords = true;
    this.keywordService.update().subscribe(
      result => {
        this.updatingKeywords = false;
        this.snackBar.open(
          'Le processus de mise à jour est terminé !',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      }
    );
  }

  public updateRegionsDfp(): void {
    this.updatingRegions = true;
    this.regionService.update().subscribe(
      result => {
        this.updatingRegions = false;
        this.snackBar.open(
          'Le processus de mise à jour est terminé !',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      }
    );
  }

  public updateVideoGroup(): void {
    this.updatingVideoGroup = true;
    this.videoService.updateVideoGroup(this.from, this.to).subscribe(
      result => {
        this.updatingVideoGroup = false;
        this.snackBar.open(
          'Le processus de mise à jour est terminé !',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      },
      error => {
        this.updatingVideoGroup = false;
        switch (error.status) {
          case 504:
            this.snackBar.open(
              'Le processus de mise à jour est actuellement en cours !',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
            break;
          default:
            this.snackBar.open(
              'Une erreur est survenue',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
            break;
        }
      }
    );
  }

  public updateAudiencesDfp(): void {
    this.updatingAudiences = true;
    this.audService.updateDfpAudiences().subscribe(
      result => {
        this.updatingAudiences = false;
        this.snackBar.open(
          'Le processus de mise à jour est terminé !',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      },
      error => {
        this.updatingAudiences = false;
        switch (error.status) {
          case 504:
            this.snackBar.open('Le processus de mise à jour est actuellement en cours !', null, {
              duration: AppConstants.snackBarDuration,
              verticalPosition: 'top'
            });
            break;
          default:
            this.snackBar.open('Une erreur est survenue', null, {
              duration: AppConstants.snackBarDuration,
              verticalPosition: 'top'
            });
            break;
        }
      }
    );
  }

  public updateCreativeId(): void {
    this.updatingCreativeId = true;
    this.creativeIdService.getCreativeId()
      .subscribe(
        result => {
          this.updatingCreativeId = false;
          this.snackBar.open(
            'Le processus de mise à jour est terminé !',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.updatingCreativeId = false;
          if (error && error.status === 504) {
            this.snackBar.open(
              'Mise à jours des Id Creative en cours',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          } else {
            this.snackBar.open(
              'Une erreur est survenue',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
          }
        }
      );
  }

  public executeTvCampaign(): void {
    this.updatingCampaign = true;
    this.creativeIdService.executeFwTv().subscribe(
      () => this.updatingCampaign = false,
      () => this.updatingCampaign = false,
    );
  }

  public updateTvCampaign(): void {
    this.updatingCampaign = true;
    this.tvCampagneService.pushTvCampaign().subscribe(
      () => this.updatingCampaign = false,
      () => this.updatingCampaign = false,
    );
  }

  public preloadFWVideos(): void {
    this.updatingCampaign = true;
    this.tvCampagneService.preloadFWVideos().subscribe(
      () => this.updatingCampaign = false,
      () => this.updatingCampaign = false,
    );
  }

  public updateOldFwAudiences(): void {
    this.updatingFwAudience = true;
    this.audService.updateOldFwAudiences().subscribe(
      result => {
        this.snackBar.open(
          'Le processus de mise à jour est terminé !',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      },
      error => {
        switch (error.status) {
          case 504:
            this.snackBar.open(
              'Le processus de mise à jour est actuellement en cours !',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
            break;
          default:
            this.snackBar.open(
              'Une erreur est survenue',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
            break;
        }
      },
      () => {
        this.updatingFwAudience = false;
      }
    );
  }

  public isLoading(): boolean {
    return this.updatingRegions
      || this.updatingKeywords
      || this.updatingVideoGroup
      || this.updatingCreativeId
      || this.updatingFwAudience
      || this.updatingAudiences
      || this.updatingCampaign
      || this.updatingLiveTracking
      || this.updatingFsa
      || this.updatingCollectionRubrique
      || this.updatingVideosRubriques
      || this.ingestingCriteria
      || this.creatingAlerts
      || this.purgingVideos
      || this.getFwIdVideos
      || this.isIngestMeteoFranceLoading
      || this.isIngestMeteoFranceUvLoading
      || this.refreshingItemOfferRangeCategories;
  }

  public updateLiveTracking(): void {
    this.updatingLiveTracking = true;
    this.liveTrackingService.updateLiveTracking()
      .subscribe(
        result => {
          this.updatingLiveTracking = false;
          this.snackBar.open(
            'Le processus de mise à jour est terminé !',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.updatingLiveTracking = false;
          if (error && error.status === 504) {
            this.snackBar.open(
              'Mise à jour du suivi du live en cours',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          } else {
            this.snackBar.open(
              'Une erreur est survenue',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
          }
        }
      );
  }

  public updateFsa(): void {
    this.updatingFsa = true;
    this.fsaService.updateFsa()
      .subscribe(
        result => {
          this.updatingFsa = false;
          this.snackBar.open(
            'Le processus de mise à jour est terminé !',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.updatingFsa = false;
          if (error && error.status === 504) {
            this.snackBar.open(
              'Mise à jour des numéro FSA en cours',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          } else {
            this.snackBar.open(
              'Une erreur est survenue',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
          }
        }
      );
  }

  public updateCollectionRubrique(): void {
    if (this.typePackLabelRubrique !== null) {
      this.updatingCollectionRubrique = true;
      this.collectionRubriqueService.updateCollectionRubrique(this.typePackLabelRubrique)
        .subscribe(
          result => {
            this.updatingCollectionRubrique = false;
            this.snackBar.open(
              'Le processus de mise à jour des rubriques est terminé !',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top', panelClass: ['chip-success']}
            );
          },
          error => {
            this.updatingCollectionRubrique = false;
            if (error && error.status === 504) {
              this.snackBar.open(
                'Timeout mais mise à jour des rubriques en cours côté API',
                null,
                {duration: 8000, verticalPosition: 'top', panelClass: ['chip-warning']}
              );
            } else {
              this.snackBar.open(
                'Une erreur est survenue',
                null,
                {duration: AppConstants.snackBarDuration, verticalPosition: 'top', panelClass: ['chip-error']});
            }
          }
        );
    } else {
      this.snackBar.open(
        'Vous avez oublié de saisir le type pack à cibler',
        null,
        {duration: AppConstants.snackBarDuration, verticalPosition: 'top', panelClass: ['chip-warning']}
      );
    }

  }

  public updateVideosRubriques(): void {
    if (this.typePackLabelVideo !== null) {
      this.updatingVideosRubriques = true;
      this.videosRubriquesService.updateVideosRubriques(this.typePackLabelVideo)
        .subscribe(
          result => {
            this.updatingVideosRubriques = false;
            this.snackBar.open(
              'Le processus de mise à jour des videos aux collections est terminé !',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top', panelClass: ['chip-success']}
            );
          },
          error => {
            this.updatingVideosRubriques = false;
            if (error && error.status === 504) {
              this.snackBar.open(
                'Veuillez patienter, le traitement est en cours d\'exécution, mise à jour des collections en cours côté API.',
                null,
                {duration: 8000, verticalPosition: 'top', panelClass: ['chip-warning']}
              );
            } else if (error && error.status === 404) {
              this.snackBar.open(
                error.message,
                null,
                {duration: AppConstants.snackBarDuration, verticalPosition: 'top', panelClass: ['chip-error']}
              );
            } else {
              this.snackBar.open(
                'Une erreur est survenue',
                null,
                {duration: AppConstants.snackBarDuration, verticalPosition: 'top', panelClass: ['chip-error']});
            }
          }
        );
    } else {
      this.snackBar.open(
        'Vous avez oublié de saisir le type pack à cibler',
        null,
        {duration: AppConstants.snackBarDuration, verticalPosition: 'top', panelClass: ['chip-warning']}
      );
    }
  }

  public generateAlerts(): void {
    this.creatingAlerts = true;
    this.alertService.generateAlerts();
    this.creatingAlerts = false;
  }

  public generateAlertsPack(): void {
    this.creatingAlerts = true;
    this.alertService.generateAlertsPack().subscribe(
      () => this.creatingAlerts = false,
      error => {
        this.snackBar.open(
          'Une erreur est survenue.',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      });
  }

  public purgeVideos(): void {
    this.purgingVideos = true;
    this.videoService.purgeVideos().subscribe(
      result => {
        this.purgingVideos = false;
        this.snackBar.open(
          result.result,
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      });
  }

  public getIdFwVideos(): void {
    this.getFwIdVideos = true;
    this.videoService.getIdFwVideos().subscribe(
      result => {
        this.getFwIdVideos = false;
        this.snackBar.open(
          'Le nombre de vidéos traitées est de : ' + result.result,
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      });
  }

  public pushTvAdrPurchaseItem(): void {
    this.pushingTvAdrPurchaseItem = true;
    this.purchaseItemService.pushTvAdrPurchaseItemToFw()
      .subscribe(
        result => {
          this.pushingTvAdrPurchaseItem = false;
          this.snackBar.open(
            'Le push est terminé !',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.pushingTvAdrPurchaseItem = false;
          if (error && error.status === 504) {
            this.snackBar.open(
              'Push des dispositifs Tv Adressés cours',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          } else {
            this.snackBar.open(
              'Une erreur est survenue',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
          }
        }
      );

  }

  public injestTv5Monde(): void {
    this.mogadorService.injest().subscribe();
  }

  public upadateSubstitutionAudiences(): void {
    this.croiseTvDigitalService.upadateSubstitutionAudiences().subscribe();
  }

  public upadateDetailSpotByDay(): void {
    this.detailSpotByDayService.maj().subscribe();
  }

  public createTvSegCampaign(): void {
    this.creatingTvSegCampaign = true;
    this.campaignService.createTvSegCampaign()
      .subscribe(
        result => {
          this.creatingTvSegCampaign = false;
          this.snackBar.open(
            'La création des campagnes TV Segmentée est terminée !',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.creatingTvSegCampaign = false;
          if (error && error.status === 504) {
            this.snackBar.open(
              'La création des campagnes TV Segmentée est en cours',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          } else {
            this.snackBar.open(
              'Une erreur est survenue',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
          }
        }
      );
  }

  public pushLinearSpot(): void {
    this.creatingTvSegCampaign = true;
    this.campaignService.pushLinearSpot()
      .subscribe(
        result => {
          this.creatingTvSegCampaign = false;
          this.snackBar.open(
            'Le process est terminé !',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.creatingTvSegCampaign = false;
        }
      );
  }

  public pushSpotToDuplicate(): void {
    this.creatingTvSegCampaign = true;
    this.campaignService.pushSpotToDuplicate()
      .subscribe(
        result => {
          this.creatingTvSegCampaign = false;
          this.snackBar.open(
            'Le process est terminé !',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.creatingTvSegCampaign = false;
        }
      );
  }

  public pushTvSpot(): void {
    this.creatingTvSegCampaign = true;
    this.campaignService.pushTvSpot()
      .subscribe(
        result => {
          this.creatingTvSegCampaign = false;
          this.snackBar.open(
            'Le process est terminé !',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.creatingTvSegCampaign = false;
        }
      );
  }

  public pushTvScreen(): void {
    this.creatingTvSegCampaign = true;
    this.campaignService.pushTvScreen()
      .subscribe(
        result => {
          this.creatingTvSegCampaign = false;
          this.snackBar.open(
            'Le process est terminé !',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.creatingTvSegCampaign = false;
        }
      );
  }

  public pushLinearCampaignToFw(): void {
    this.pushAudiencesitemsFw = true;

    this.campaignService.pushLinearCampaignToFw()
      .subscribe(
        result => {
          this.creatingTvSegCampaign = false;
          this.snackBar.open(
            'Le process est terminé !',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.pushAudiencesitemsFw = false;
        }
      );

  }

  /**
   *
   */
  public generateMultipleOfficeAlert(): void {
    this.creatingAlerts = true;
    this.alertService.generateMultipleOfficeAlert().subscribe(
      () => this.creatingAlerts = false,
      error => {
        this.snackBar.open(
          'Une erreur est survenue.',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      });
  }

  public calculCaBySite(): void {
    this.purchaseItemService.calculCaBySite().subscribe();
  }

  /**
   *
   */
  public generateNewTimeToShopItemAlerts(): void {
    this.creatingAlerts = true;
    this.alertService.generateNewTimeToShopItemAlerts().subscribe(
      () => this.creatingAlerts = false,
      error => {
        this.snackBar.open(
          'Une erreur est survenue.',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      });
  }

  public generateBillPurchaseItemsAlert(): void {
    this.creatingAlerts = true;
    this.alertService.generateBillPurchaseItemsAlert().subscribe(
      () => this.creatingAlerts = false,
      error => {
        this.snackBar.open(
          'Une erreur est survenue.',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      });
  }

  public ingestMeteoFrance(): void {
    this.isIngestMeteoFranceLoading = true;
    this.meteoFranceService.getMeteoFranceCSVFiles().subscribe(
      () => this.isIngestMeteoFranceLoading = false,
      error => {
        this.isIngestMeteoFranceLoading = false;
        let message = '';
        if (error.code === 504) {
          message = 'Intégration en cours, veuillez patientez...';
        } else {
          message = 'Une erreur est survenue.';
        }
        this.snackBar.open(
          message,
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      });
  }

  public ingestMeteoUvFrance(): void {
    this.isIngestMeteoFranceUvLoading = true;
    this.meteoFranceService.getMeteoFranceUvCSVFiles().subscribe(
      () => this.isIngestMeteoFranceUvLoading = false,
      error => {
        this.isIngestMeteoFranceUvLoading = false;
        let message = '';
        if (error.code === 504) {
          message = 'Intégration en cours, veuillez patientez...';
        } else {
          message = 'Une erreur est survenue.';
        }
        this.snackBar.open(
          message,
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      });
  }

  public pushCreativeToFw() {
    this.pushingCreativeManagementToFW = true;
    this.creativeManagementService
      .pushCreativeManagementToFw()
      .subscribe(
        (response) => {
          this.pushingCreativeManagementToFW = false;
          this.snackBar.open(
            'Le push des films est terminé',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.pushingCreativeManagementToFW = false;
          this.snackBar.open(
            error.error.detail,
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        }
      );
  }

  refreshItemOfferRangeCategoryData(isFirstRun = false) {
    this.refreshingItemOfferRangeCategories = true;
    this.itemOfferRangeCategoryService.refreshDataInDB({isFirstRun: isFirstRun ? 1 : 0}).subscribe(
      () => {
        this.snackBar.open(
          'Les données ont été actualisées.',
          null,
          {duration: 2000, verticalPosition: 'top'}
        );
        this.refreshingItemOfferRangeCategories = false;
      },
      error => {
        if (error.status === 504 || error.status === 409) {
          setTimeout(() => {
            this.refreshItemOfferRangeCategoryData(false);
          }, 10000);
        } else {
          this.refreshingItemOfferRangeCategories = false;
        }
      }
    );
  }

  refreshPacksButtonHandler() {
    this.refreshItemOfferRangeCategoryData(true);
  }

  public updateFwCampaignTestId() {
    this.updatingFwCampaignTestId = true;
    this.freewheelService
      .updateFwCampaignTestId(this.fwCampaignId)
      .subscribe(
        result => {
          this.updatingFwCampaignTestId = false;
          this.snackBar.open(
            'Le processus de mise à jour est terminé !',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.updatingFwCampaignTestId = false;
          this.snackBar.open(
            error.error.detail,
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
        }
      );
  }

  pushSpotCombinationsAWS() {
    this.isPushingSpotCombinationsToAWS = true;
    this.multispotPushService.updateSpotCombinations().subscribe(
      () => {
        this.isPushingSpotCombinationsToAWS = false;
        this.snackBar.open(
          'Mise à jour de croisements possibles est terminé.',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      },
      error => {
        this.isPushingSpotCombinationsToAWS = false;
        this.snackBar.open(
          error.error.detail,
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
      }
    );
  }

  pushNbAdsDurationsAWS() {
    this.multispotPushService.updateNbAdsDurations().subscribe(
      () => {
        this.isPushingNbAdsDurationsAWS = false;
        this.snackBar.open(
          'Mise à jour de nb ads durations est terminé.',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      },
      error => {
        this.isPushingNbAdsDurationsAWS = false;
        this.snackBar.open(
          error.error.detail,
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
      }
    );
  }

  pushSpecificEligiblePositionsAWS() {
    this.multispotPushService.updateSpecificEligiblePositions().subscribe(
      () => {
        this.isPushingNbAdsDurationsAWS = false;
        this.snackBar.open(
          'Mise à jour de positions spécifiques est terminé.',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      },
      error => {
        this.isPushingNbAdsDurationsAWS = false;
        this.snackBar.open(
          error.error.detail,
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
      }
    );
  }

  public updateAdUnit(): void {
    this.updatingAdUnit = true;
    this.adUnitService.getAdUnits()
      .subscribe(
        result => {
          this.updatingAdUnit = false;
          this.snackBar.open(
            'Le processus de mise à jour est terminé !',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.updatingAdUnit = false;
          if (error && error.status === 504) {
            this.snackBar.open(
              'Récupération des Ad Units en cours',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          } else {
            this.snackBar.open(
              'Une erreur est survenue',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
          }
        }
      );
  }

  public checkAudienceDiff(): void {
    this.creatingAlerts = true;
    this.alertService.checkAudienceDiff().subscribe(
      () => this.creatingAlerts = false,
      error => {
        this.snackBar.open(
          'Une erreur est survenue.',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      });
  }

  public uploadIptvFileKantar(): void {
    this.isSendingFileKantar = true;
    this.kantarService.pushIptvFile().subscribe(
      result => {
        this.isSendingFileKantar = false;
        this.snackBar.open(
          'Le processus est terminé !',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      },
      error => {
        this.isSendingFileKantar = false;
        if (error && error.status === 504) {
          this.snackBar.open(
            'Le processus est en cours',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        } else {
          this.snackBar.open(
            'Une erreur est survenue',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
        }
      });
  }

  public sendMailKantarFilesExist() {
    this.isSendingMailKantarFilesExist = true;
    this.kantarService.sendMailKantarFilesExist().subscribe(
      result => {
        this.isSendingMailKantarFilesExist = false;
        this.snackBar.open(
          result,
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      },
      error => {
        this.isSendingMailKantarFilesExist = false;
        this.snackBar.open(
          'Une erreur est survenue',
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
      });
  }

  /**
   * Synchronize buyers.
   *
   * @param since
   */
  public syncBuyer(since: number = null) {
    this.buyerService.syncBuyer(since)
      .subscribe(
        response => {
          this.snackBar.open(
            'Le processus de mise à jour des vendeurs est terminé.',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        }, error => {
          if (error.status == 504) {
            this.snackBar.open(
              'Le processus de mise à jour des vendeurs ne répond plus (timeout).',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          } else {
            this.snackBar.open(
              'Le processus de mise à jour des vendeurs a échoué.',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          }

        });
  }
  
  public recalculateImpressionVol() {
    this.updatingAudiences = true;
    this.audService
      .updateDeliveredUnit()
      .subscribe(
        (response) => {
          this.updatingAudiences = false;
          this.snackBar.open(
            "Recalcul du volume d'impression terminé",
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          this.updatingAudiences = false;
        }
      );
  }

  public recalculateAudiences() {
    this.updatingAudiences = true;
    this.audienceService
      .updateAudiences()
      .subscribe(
        (response) => {
          this.updatingAudiences = false;
          this.snackBar.open(
            'Recalcul des volume des audiences terminé',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        },
        error => {
          switch (error.status) {
            case 504:
              this.snackBar.open(
                'Le processus de mise à jour est actuellement en cours !',
                null,
                {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
              this.updatingAudiences = false;
              break;
            default:
              this.snackBar.open(
                'Une erreur est survenue',
                null,
                {duration: AppConstants.snackBarDuration, verticalPosition: 'top'});
              this.updatingAudiences = false;
              break;
          }
        }
      );
  }

  public generateAndDownloadCrossMediaXMLReport(): void {
    this.crossMediaService.generateCrossMediaXMLReport().subscribe(
      (data) => {
        this.snackBar.open(
          `La génération du rapport ${data.fileName} est terminée !`,
          null,
          {duration: this.crossMediaSnackbarSuccessDuration, verticalPosition: 'top'}
        );
        this.crossMediaService.downloadFile(data.fileContent, data.fileName, 'application/xml');
      },
      error => {
        this.snackBar.open(
          error.error.detail,
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      }
    );
  }

  public generateAndUploadCrossMediaXMLReport(): void {
    this.crossMediaService.generateAndUploadCrossMediaXMLReport().subscribe(
      (data) => {
        this.snackBar.open(
          `Le rapport ${data.fileName} a été envoyé chez Médiamétrie !`,
          null,
          {duration: this.crossMediaSnackbarSuccessDuration, verticalPosition: 'top'}
        );
      },
      error => {
        this.snackBar.open(
          error.error.detail,
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      }
    );
  }

  public generateAndDownloadAdserverLog(): void {
    this.crossMediaService.generateCrossMediaAdserverLog().subscribe(
      (data) => {
        this.snackBar.open(
          `La génération du fichier ${data.fileName} est terminée !`,
          null,
          {duration: this.crossMediaSnackbarSuccessDuration, verticalPosition: 'top'}
        );
        this.crossMediaService.downloadFile(data.fileContent, data.fileName, 'text/csv');
      },
      error => {
        this.snackBar.open(
          error.error.detail,
          null,
          {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
        );
      }
    );
  }

  /**
   * Update countries from equativ
   */
  public updateCountry() {
    this.equativService.updateCountry()
      .subscribe(
        response => {
          this.snackBar.open(
            'Mise à jours des pays terminée.',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        }, error => {
          if (error.status == 504) {
            this.snackBar.open(
              'Le processus de mise à jour ne répond plus (timeout).',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          } else {
            this.snackBar.open(
              'Le processus de mise à jour a échoué.',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          }

        });
  }

  /**
   * Update region from equativ
   */
  public updateRegion() {
    this.equativService.updateRegion()
      .subscribe(
        response => {
          this.snackBar.open(
            'Mise à jours des regions terminée.',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        }, error => {
          if (error.status == 504) {
            this.snackBar.open(
              'Le processus de mise à jour ne répond plus (timeout).',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          } else {
            this.snackBar.open(
              'Le processus de mise à jour a échoué.',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          }

        });
  }

  public updateCity() {
    this.equativService.updateCity()
      .subscribe(
        response => {
          this.snackBar.open(
            'Mise à jours des villes terminée.',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        }, error => {
          if (error.status == 504) {
            this.snackBar.open(
              'Le processus de mise à jour ne répond plus (timeout).',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          } else {
            this.snackBar.open(
              'Le processus de mise à jour a échoué.',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          }

        });
  }

  public updateState() {
    this.equativService.updateState()
      .subscribe(
        response => {
          this.snackBar.open(
            'Mise à jours des departements terminée.',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        }, error => {
          if (error.status == 504) {
            this.snackBar.open(
              'Le processus de mise à jour ne répond plus (timeout).',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          } else {
            this.snackBar.open(
              'Le processus de mise à jour a échoué.',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          }

        });
  }
  
  public updateOs() {
    this.equativService.updateOs()
      .subscribe(
        response => {
          this.snackBar.open(
            'Mise à jours des OS terminée.',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        }, error => {
          if (error.status == 504) {
            this.snackBar.open(
              'Le processus de mise à jour ne répond plus (timeout).',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          } else {
            this.snackBar.open(
              'Le processus de mise à jour a échoué.',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          }

        });
  }

  public updateBrowser() {
    this.equativService.updateBrowser()
      .subscribe(
        response => {
          this.snackBar.open(
            'Mise à jours des navigateurs terminée.',
            null,
            {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
          );
        }, error => {
          if (error.status == 504) {
            this.snackBar.open(
              'Le processus de mise à jour ne répond plus (timeout).',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          } else {
            this.snackBar.open(
              'Le processus de mise à jour a échoué.',
              null,
              {duration: AppConstants.snackBarDuration, verticalPosition: 'top'}
            );
          }

        });
    }
}
