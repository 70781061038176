import { Component, OnInit } from '@angular/core';
import { PurchaseItemDetailComponent } from '../purchase-item-detail/purchase-item-detail.component';

@Component({
  selector: 'app-purchase-item-detail-gag',
  templateUrl: './purchase-item-detail-gag.component.html',
  styleUrls: ['./purchase-item-detail-gag.component.scss']
})
export class PurchaseItemDetailGagComponent extends PurchaseItemDetailComponent implements OnInit {

  protected $barProgressionTrendClass = 'mat-progress-bar-trend-value';

  ngOnInit() {
    this.initForm();
    this.initForecast();
    this.initValueChanges();
    this.purchaseStep = this.purchase._embedded.step.step_num;
    this.isFilmOk = this.checkFilms(this.item.films || null);
    this.initPrereservationValues(this.item.purchaseItem);
    this.initFilmEncodingState() ;
  }
}
